import "./App.css";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { UserProvider, ArcadeProvider, CommentProvider } from "./state";
import Arcade from "./components/Arcade";
import Play from './pages/play'
import './css/main.css'

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0,0,20,0.3)",
      },
    },
  },
  palette: {
    primary: {
      light: "#99bee0",
      main: "#0085ff",
      dark: "0085ff",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    text: {
      primary: "#555e78",
      secondary: "#868686",
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif;",
    h1: {
      fontFamily: '"Fredoka One", cursive',
      fontSize: '1.6rem',
      color: '#515873',
      webkitFontSmoothing: "antialiased",
      marginBottom: 8
    },
    h2: {
      fontFamily: '"Fredoka One", cursive',
      fontSize: '1.3rem',
      color: '#515873',
      webkitFontSmoothing: "antialiased",
      marginBottom: 12
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 150,
      lineHeight: 1.5
    },
    subtitle2: {
      color: '#515873',
      fontSize: '1rem',
      lineHeight: 1.5
    }
  },
});

const AppWrapper = () => (
  <Router>
    <Route path="*">
      <App />
    </Route>
  </Router>
);

function App() {
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <ArcadeProvider>
          <CommentProvider>
            <Switch location={location}>
              <Route
                exact path="/"
                component={Arcade}
              />
              <Route
                path='/play/:id'
                component={Play}
              />
            </Switch>
          </CommentProvider>
        </ArcadeProvider>
      </UserProvider>
    </ThemeProvider>
  );
}

export default AppWrapper;
