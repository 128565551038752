const feathers = require("@feathersjs/feathers");
const rest = require("@feathersjs/rest-client");
const auth = require("@feathersjs/authentication-client");
const axios = require("axios");

export const client = feathers();

// Connect to the same as the browser URL (only in the browser)
const restClient = rest(process.env.REACT_APP_GAMEFROOT_SERVICE);

client.configure(auth({ storageKey: "feathers-jwt" }));

// Configure an AJAX library (see below) with that client;
client.configure(restClient.axios(axios));

export const userService = client.service('user')
export const projectService = client.service('projects')
export const likeService = client.service('games-likes')
export const commentService = client.service('game-comments')
export const lessonRecordService = client.service('member-lesson-records')

// export const rbacService = client.service('rbac');
// export const groupService = client.service("groups");
// export const memberService = client.service("members");
// export const gcloudService = client.service("gcloud");
// export const versionService = client.service("versions");
// export const emailsService = client.service("emails");
// export const marketplaceService = client.service("marketplace");
// export const downloadService = client.service("download");
// export const packService = client.service("packs");
// export const lessonService = client.service("lessons");
// export const progressService = client.service("progress");
// export const attendanceService = client.service("attendance");
// export const eventService = client.service("events");
// export const notificationService = client.service("notifications");
// export const assetService = client.service("assets");
// export const authManagementService = client.service('auth-management')
// export const gameService = client.service('games');