import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useArcadeStore } from '../../state'

import makeStyles from '@material-ui/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Container, IconButton } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Carousel from 'react-material-ui-carousel'

import GameCard from '../common/GameCard'
import Error from '../common/Error'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    maxWidth: 860,
    margin: 'auto',
    paddingTop: 24
  },
  text: {
    fontFamily: '"Fredoka One", cursive;',
    WebkitFontSmoothing: 'antialiased',
    textTransform: 'none',
    fontSize: 20,
    // backfaceVisibility: 'hidden',
    color: '#FFF',
    marginBottom: 12
  },
  iconButton: {
    position: 'relative',
    borderRadius: 5,
    margin: 10
  },
  icon: {
    color: '#FFF',
    fontSize: 58
  }
}))



const FeaturedCarousel = () => {
  const classes = useStyles()
  const useArcade = useArcadeStore()
  const { 
    featuredGames,
    featuredGamesError
  } = useArcade
  const [items, setItems] = useState([])
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const games = [...featuredGames]
    const CHUNK_SIZE = window.innerWidth < 600 ? 3 : 4

    const list = Array.from({ length: Math.ceil(games.length / CHUNK_SIZE) }, (v, i) => games.slice(i * CHUNK_SIZE, i * CHUNK_SIZE + CHUNK_SIZE))

    setItems(list)
  }, [featuredGames])

  const changePage = num => {
    const max = items.length - 1

    let i = index + num
    if (i < 0) i = max
    else if (i > max) i = 0

    setIndex(i)
  }

  return (
    <>
      <div className={classes.title} >
        <Typography className={classes.text}>Staff Picks</Typography>
      </div>
      <Container className={classes.container}>
        {
          items.length > 1 &&
          <IconButton className={classes.iconButton} onClick={() => changePage(-1)}>
            <ChevronLeftIcon className={classes.icon} />
          </IconButton>
        }

        <div style={{ maxWidth: 860 }}>
          {featuredGamesError ? (
            <Error message={featuredGamesError} color='white' />
          ) : (
            <Carousel
              autoPlay={false}
              index={index}
              navButtonsAlwaysInvisible
              indicators={false}
              timeout={250}
            >
              {items.map((games, j) => (
                <Grid key={j} container spacing={2}>
                  {games.map((game, i) => (
                    <Grid xs={4} sm={3} item key={i}>
                      <GameCard key={game.id} i={i} {...game} />
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Carousel>

          )}
        </div>

        {
          items.length > 1 &&
          <IconButton className={classes.iconButton} onClick={() => changePage(1)}>
            <ChevronRightIcon className={classes.icon} />
          </IconButton>
        }
      </Container>
    </>

  )
}

export default observer(FeaturedCarousel)
