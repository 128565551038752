import { makeAutoObservable } from 'mobx'

import {
  fetchCommentsByGame,
  createComment,
  updateComment,
  deleteComment,
} from '../api/comments'


class CommentStore {
  INITIAL_FETCH_SIZE = 2
  EXTENSION_FETCH_SIZE = 20
  MAX_COMMENT_LENGTH = 1000

  size = this.INITIAL_FETCH_SIZE

  comments = []

  body = ''
  editBody = ''
  remainingCharacters = this.MAX_COMMENT_LENGTH

  loadingCommentsError = undefined
  loadingComments = false
  outOfComments = false;
  submittingComment = false

  totalResults
  search = ''

  gameId = 0
  CommentCUDError

  constructor() {
    makeAutoObservable(this)
  }

  // <--- Actions --->
  reset() {
    this.comments = []
    this.search = ''
    this.body = ''
    this.remainingCharacters = this.MAX_COMMENT_LENGTH
    this.totalResults = 0
    this.size = this.INITIAL_FETCH_SIZE
    this.gameId = null
  }

  getRemainingCharacters() {
    return this.MAX_COMMENT_LENGTH - this.body.length
  }

  /**
   * Set search query
   * @param {String} value search value
   */
  setSearch(value) {
    this.search = value
  }

  /**
   * extend size of current selection, used for infinite scroll
   */
  extendSelection() {
    this.extendCommentSelection()
    this.size += this.INITIAL_FETCH_SIZE / 2
  }

  updateBody(body) {
    if (body.length <= this.MAX_COMMENT_LENGTH)
      this.body = body
    this.remainingCharacters = this.MAX_COMMENT_LENGTH - body.length
  }

  updateEdit(edit) {
    if (edit.length <= this.MAX_COMMENT_LENGTH) {
      this.editBody = edit
    }
  }

  resetBody() {
    this.body = ''
    this.remainingCharacters = this.MAX_COMMENT_LENGTH
  }

  submitCreateComment(username, userId) {
    this.createComment(username, userId)
    this.resetBody()
  }

  /**
   * Restores deleted comment ( on server error )
   * @param {Number} index 
   * @param {Object} comment 
   */
  restoreComment(index, comment) {
    this.comments = this.comments.slice(0, index).concat(
      [comment],
      this.comments.slice(index)
    )
    this.totalResults += 1
  }

  /**
   * reset current selection size
   */
  resetSelection(gameId) {
    this.reset()
    this.gameId = gameId
    this.initialFetch()
  }

  /**
 * Fetch initial comments for game
 * Go through results titles to ensure no inappropriate words.
 * Adjust game total when games are removed for adult content. 
 * @param {Number} projectId project id
 */
  async initialFetch(gameId) {
    this.gameId = gameId
    this.comments = []
    this.loadingComments = true
    this.loadingCommentsError = undefined
    this.outOfComments = false

    try {
      const { data, flags } = await fetchCommentsByGame({ gameId, limit: this.INITIAL_FETCH_SIZE })

      this.comments = data
      this.totalResults = data.length

      if (this.totalResults < this.size) this.outOfComments = true
      if (flags && flags.endOfResults) this.outOfComments = true
    } catch (err) {
      this.loadingCommentsError = 'Failed to load comments.  Please refresh the page and try again'
      this.outOfComments = true
      console.error(err)
    } finally {
      this.loadingComments = false
    }
  }

  async extendComments(amount = this.EXTENSION_FETCH_SIZE) {
    this.loadingCommentsError = undefined
    this.loadingComments = true

    try {
      const { data, flags } = await fetchCommentsByGame({ gameId: this.gameId, limit: amount, offset: this.size })

      if (!data.length) {
        this.loadingCommentsError = "Out of Comments"
        this.outOfComments = true
        return
      }

      this.comments = [...this.comments, ...data]
      this.totalResults += data.length
      this.size += amount

      if (this.totalResults < this.size) this.outOfComments = true
      if (flags && flags.endOfResults) this.outOfComments = true
    } catch (err) {
      this.loadingCommentsError = 'Failed to load comments.  Please refresh the page and try again'
      this.outOfComments = true
      console.error(err)
    } finally {
      this.loadingComments = false
    }
  }

  /**
    * create comment on the game.
    * @param {Number} projectId project id
    * @param {String} username
    * @param {Number} userId
    */
  async createComment({ gameId, parentId, username, id }) {
    if (this.body.length) {
      this.submittingComment = true

      try {
        const commentId = await createComment(gameId, this.body, parentId)

        const comment = {
          author: {
            username,
            id
          },
          body: this.body,
          userId: id,
          ...(parentId && { parent_comment_id: parentId }),
          game_id: gameId,
          id: commentId
        }

        this.updateBody('')
        this.totalResults += 1

        if (parentId) {
          const parentComment = this.comments.find(comment => comment.id === parentId)

          parentComment.children ? parentComment.children.push(comment) : parentComment.children = [comment]
        } else {
          this.comments.unshift(comment)
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.submittingComment = false
      }
    }
  }

  async editComment(id, parentId) {
    this.submittingComment = true

    try {
      await updateComment(id, this.editBody)

      if (parentId) {
        const parentIndex = this.comments.findIndex(parent => parent.id === parentId)

        const child = this.comments[parentIndex].children.find(child => child.id === id)

        child.body = this.editBody
      } else {
        const comment = this.comments.find(comment => comment.id === id)

        comment.body = this.editBody
      }

      this.updateEdit('')
    } catch (err) {
      console.log(err)
    } finally {
      this.submittingComment = false
    }
  }

  /**
* delete comment from server and remove from store
* @param {Number} id comment id
*/
  async deleteComment({ id, parentId }) {
    const toDelete = [id]

    if (!parentId) { // Add children comments to delete queue
      const comment = this.comments.find(comment => (comment.id === id))
      comment.children && toDelete.push(...comment.children.map(child => child.id))
    }

    try {
      const promises = toDelete.map(async id => deleteComment(id))

      await Promise.all(promises)

      if (!parentId) { // Clean up comment array
        this.comments = this.comments.filter(comment => comment.id !== id)
      } else {
        const parent = this.comments.find(parent => parent.id === parentId)
        parent.children = parent.children.filter(child => child.id !== id)
      }

      this.totalResults -= toDelete.length
    } catch (err) {
      console.log(err)
    }
  }
}

export default CommentStore
