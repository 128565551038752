import { client } from "./client";

export const userFromToken = async (token) => {
  await client.authentication.setAccessToken(token);
  const { user } = await client.reAuthenticate();
  return user;
};

export const removeToken = () => client.removeAccessToken();

