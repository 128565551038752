import { Component, createRef } from 'react'

import { forceIframeFocus } from '../../utils/forceIframeFocus'

class GameFrame extends Component {
  constructor(props) {
    super(props)
    this.iframeRef = createRef()
  }

  render() {
    const { src } = this.props

    return (
      <iframe
        style={{ border: 'none' }}
        autoFocus
        src={src}
        ref={this.iframeRef}
        onLoad={() => forceIframeFocus(this.iframeRef)}
        width={'100%'}
        height={600}
        title='Game Frame'
      />
    )
  }
}

export default GameFrame