import React from 'react';
import Grid from "@material-ui/core/Grid"
import { makeStyles } from '@material-ui/core/styles';

import GameFrameOuter from './GameFrameOuter'
import MoreGames from './MoreGames'
import GameDiscussion from './GameDiscussion'

const useStyles = makeStyles({
  root: {
    paddingTop: 10,
    width: '100%',
    height: '100%'
  },
  feedContainer: {
    padding: 15,
    height: '100%'
  },
  gameContainer: {
    height: '100%'
  }
});

const GameLayout = ({ url, gameId }) => {
  const classes = useStyles()

  return (
      <Grid className={classes.root} container>
        <Grid className={classes.gameContainer} item xs={9}>
          <GameFrameOuter url={url} />
          <GameDiscussion gameId={gameId} />
        </Grid>
        <Grid className={classes.feedContainer} item xs={3}>
          <MoreGames id={Number(gameId)} />
        </Grid>
      </Grid>
  );
}

export default GameLayout
