import { makeAutoObservable } from 'mobx'
import {
  fetchProjects,
  fetchProject,
  fetchLikedGames,
  fetchFeaturedGames,
  likeGame,
  unlikeGame
} from '../api/arcade'

class ArcadeStore {
  INITIAL_FETCH_SIZE = 48
  EXTENSION_FETCH_SIZE = 24

  latestGames = []
  loadingGames = false
  loadingGameError = undefined

  likedGames = []
  loadingLikedGames = false
  likedGamesError = undefined

  featuredGames = []
  loadingFeaturedGames = false
  featuredGamesError = undefined

  outOfGames = false
  search = ''
  lastSearch = ''
  totalResults = 0

  // Need to build out object as persistance is lost on route change - undefined causes crash
  gameInFocus = {
    comments: [],
    createdAt: '',
    creator: {
      username: ''
    },
    currentVersion: 1,
    description: '',
    id: null,
    images: null,
    likes: {},
    meta: {},
    name: '',
    owner: null,
    publicAccess: '',
    thumbnail: null,
    updatedAt: '',
    url: ''
  }

  size = this.INITIAL_FETCH_SIZE

  constructor() {
    makeAutoObservable(this)
  }

  // <--- Actions --->

  resetSelection(amount = this.INITIAL_FETCH_SIZE) {
    this.latestGames = []
    this.initialFetchGames(amount)
    this.size = amount
    this.lastSearch = this.search
  }

  extendSelection = amount => {
    this.extendGameSelection(amount)
  }

  resetFocus() {
    this.gameInFocus = {
      comments: [],
      createdAt: '',
      creator: {
        username: ''
      },
      currentVersion: 1,
      description: '',
      id: null,
      images: null,
      likes: {},
      meta: {},
      name: '',
      owner: null,
      publicAccess: '',
      thumbnail: null,
      updatedAt: '',
      url: ''
    }
  }

  setFocusGame(id) {
    const gameInFocus = this.latestGames.find(game => {
      return game.id === Number(id)
    })

    if (gameInFocus) {
      this.gameInFocus = gameInFocus
    } else {
      this.fetchSingle(id)
    }
  }

  setFeaturedGames(games) {
    this.featuredGames = games
  }

  setLatestGames(games) {
    this.latestGames = games
  }

  setLikedGames(games) {
    this.likedGames = games
  }

  removeUnlikedGames() {
    this.likedGames = this.likedGames.filter(game =>
      game.likes.likedByMe
    )
  }

  setSearch(val) {
    this.search = val

    if (val === '') this.doSearch()
  }

  doSearch() {
    if (this.search !== this.lastSearch) {
      this.lastSearch = this.search
      this.resetSelection()
    }
  }

  // <--- Flow --->
  async fetchSingle(id) {
    this.loadingGameError = undefined
    try {
      this.gameInFocus = await fetchProject(id)
    } catch (err) {
      this.loadingGameError = 'Failed to load game.  Please refresh the page and try again'
      console.log(err)
    }
  }

  /**
 * Fetch games by search query and offset
 */
  async initialFetchGames(amount = this.INITIAL_FETCH_SIZE) {
    if (this.latestGames.length === 0 || this.latestGames.length > 80) {
      this.setLatestGames([])
      this.loadingGames = true
      this.loadingGameError = undefined
      this.outOfGames = false
      
      try {
        const { total, data, flags } = await fetchProjects({ searchQuery: this.lastSearch, limit: amount })
        this.setLatestGames(data)
        this.totalResults = total
        
        if (this.totalResults < this.INITIAL_FETCH_SIZE) this.outOfGames = true
        
        if (flags && flags.endOfResults) this.outOfGames = true
      } catch (err) {
        this.loadingGameError = 'Failed to load latest games.  Please refresh the page and try again'
        console.log(err)
      } finally {
        this.loadingGames = false
      }
    }
  }

  async fetchFeaturedGames() {
    if (!this.featuredGames.length) {
      this.loadingFeaturedGames = true
      this.featuredGamesError = undefined
      try {
        const { data } = await fetchFeaturedGames()
        this.setFeaturedGames(data)
      } catch (err) {
        this.featuredGamesError = 'Failed to load featured games. Please refresh the page and try again'
        console.log(err)
      } finally {
        this.loadingFeaturedGames = false
      }
    }
  }

    /**
   * Fetch games that the current user has liked
   */
  async fetchLikedGames(id) {
    if (this.likedGames.length === 0) {
      this.likedGamesError = undefined
      this.loadingLikedGames = true

      try {
        const { data } = await fetchLikedGames(id)
        this.likedGames = data
      } catch (err) {
        this.likedGamesError = 'Failed to load favourites.  Please refresh the page and try again'
        console.log(err)
      } finally {
        this.loadingLikedGames = false
      }
    }
  }

  /**
 * Fetch more games and concatenate with the current result set
 */
  async extendGameSelection(amount = this.EXTENSION_FETCH_SIZE) {
    this.loadingGameError = undefined
    this.loadingGames = true
    try {
      const { data, flags } = await fetchProjects({ offset: this.size, limit: amount, searchQuery: this.lastSearch })
      if (!data.length) {
        this.loadingGamesError = "Out of Games"
        return
      }
      this.latestGames = this.latestGames.concat(data)
      this.size += amount

      if (this.latestGames.length < this.size) this.outOfGames = true

      if (flags && flags.endOfResults) this.outOfGames = true
    } catch (err) {
      this.loadingGameError = 'Failed to load more games.  Please refresh the page and try again'
      console.log(err)
    } finally {
      this.loadingGames = false
    }
  }

  async likeGame(id) {
    this.likeError = undefined
    try {
      await likeGame(id)
      const game = this.getGame(id)
      game.likes.likedGames = true
      game.likes.total += 1
      this.likedGames.push(game)

      const latest = this.latestGames.find(game => game.id === id)
      latest.likes.likedByMe = true
    } catch (err) {
      this.likeError = err
      console.log(err)
    }
  }

  async unlikeGame(id) {
    this.likeError = undefined
    try {
      await unlikeGame(id)
      const gameToUnlike = this.likedGames.find(game => game.id === id)
      gameToUnlike.likes.likedByMe = false
      gameToUnlike.likes.total -= 1
      this.likedGames = this.likedGames.filter(game => game.id !== id)

      const latest = this.latestGames.find(game => game.id === id)
      latest.likes.likedByMe = false
    } catch (err) {
      this.likeError = err
      console.log(err)
    }
  }
}

export default ArcadeStore