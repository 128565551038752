import React, { useState } from 'react'
import { useArcadeStore } from '../state'
import { observer } from 'mobx-react-lite'

import makeStyles from '@material-ui/styles/makeStyles'
import Container from '@material-ui/core/Container'
import { Grid } from '@material-ui/core'

import AppBar from '../components/common/AppBar'
import FeaturedCarousel from '../components/index/FeaturedCarousel'
import CategoryTabs from '../components/index/CategoryTabs'
import LatestGames from '../components/index/LatestGames'
import LikedGames from '../components/index/LikedGames'
import useSticky from '../hooks/useSticky'

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "calc(100vh - 48px)",
    backgroundColor: "#1a99ff"
  },
  container: {
    marginTop: 48,
    width: "100%",
    height: "100%",
  },
  stickyContainer: {
    position: 'fixed',
    top: 48,
    zIndex: 2,
  },
  stickyWrapper: {
    position: 'fixed',
    top: 48,
    left: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: "#1a99ff",
  },
  gameGrid: {
    maxWidth: 860,
    margin: 'auto'
  }
}));

const Home = () => {
  const classes = useStyles()
  const arcadeStore = useArcadeStore()
  const [value, setValue] = useState(0)

  const { isSticky, element} = useSticky()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // useEffect(() => {
  //   // arcadeStore.fetchFeaturedGames()
  //   arcadeStore.initialFetchGames()

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <>
      <AppBar isHome={true} />
      <div className={classes.root} >
        <Container className={classes.container}>
          {
            arcadeStore.featuredGames.length > 0 &&
            <FeaturedCarousel />
          }
          <div className={classes.gameGrid}>
            <Grid
              container
              item
              justify='space-between'
              alignItems='center'

            >
              {/* Provides background for stuck bar */}
              {
                isSticky &&
                  <div className={classes.stickyWrapper} style={{ height: element.current?.clientHeight }}/>
              }
              <Grid
                item
                ref={element}
                className={isSticky ? classes.stickyContainer : undefined}
                >
                <CategoryTabs value={value} handleChange={handleChange} />
              </Grid>


              {/* Placeholder div to stop content jumping around */}
              {
                isSticky &&
                  <div style={{ height: element.current?.clientHeight }} />
              }

            </Grid>
            {value === 0 && <LatestGames />}
            {value === 1 && <LikedGames />}
          </div>
        </Container>
      </div>
    </>
  )
}

export default observer(Home)