import { useEffect } from 'react'
import { useArcadeStore, useUserStore } from '../../state'
import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import GameCard from '../common/GameCard'
import LoadMore from '../common/LoadMore'
import Error from '../common/Error'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4)
  }
}))

const Liked = () => {
  const classes = useStyles()
  const arcadeStore = useArcadeStore()
  const userStore = useUserStore()
  const { currentUser } = userStore
  const {
    loadingLikedGames,
    likedGames,
    likedGamesError
  } = arcadeStore

  useEffect(() => {
    // Allow user to unlike games and not have be removed from Liked Games until the tab is reloaded.
    currentUser && arcadeStore.removeUnlikedGames(currentUser.id)
    arcadeStore.fetchLikedGames(currentUser.id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {likedGames.map((game, i) =>
          <Grid xs={4} sm={3} item>
            <GameCard key={game.id} i={i} {...game} />
          </Grid>
        )}
      </Grid>

      {likedGamesError &&
        <Error message={likedGamesError} />
      }

      <LoadMore
        auto={true}
        loading={loadingLikedGames}
        getNext={() => { }}
        hasMore={loadingLikedGames}
        displayEOR={likedGames.length > 48}
        endMarker='No more liked games... '
      />
    </div>
  )
}

export default observer(Liked)
