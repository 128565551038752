import { useState, useCallback } from 'react'
import { useArcadeStore, useUserStore } from '../state'
import debounce from 'lodash/debounce'

const DELAY = 250

function useGameLiked(id, likeStatus) {
  const arcadeStore = useArcadeStore()
  const userStore = useUserStore()

  const [current, setCurrent] = useState(likeStatus)
  const [prev, setPrev] = useState(likeStatus.likedByMe)

  /**
 * If after debouncing like toggle there has been a change in the status of the like
 * then begins the appropriate action to commit to database.
 * @param {Boolean} like
 */
  const likeGame = (prevLike, nextLike) => {
    if (nextLike !== prevLike) {
      if (prevLike) {
        arcadeStore.unlikeGame(id)
      } else {
        arcadeStore.likeGame(id)
      }
      setPrev(nextLike)
    }
  }

  /**
 * Use callback will ensure debounced function is only defined once 
 * otherwise it will create a new debounced function each render.
 */
// eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceLikeGame = useCallback(debounce(likeGame, DELAY), [])

  const handleLike = () => {
    if (!userStore.currentUser.id) return

    const newLikeState = !current.likedByMe
    setCurrent({
      total: current.total + (newLikeState ? 1 : -1),
      likedByMe: newLikeState
    })
    debounceLikeGame(prev, newLikeState)
  }

  return [current, handleLike]
}

export default useGameLiked



