import {  userFromToken } from "../api/auth";
import { makeAutoObservable } from "mobx";

class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  // <--- Observable --->
  currentUser = {
    id: null,
    username: '',
    subscriptionType: '',
    firstName: '',
    lastName: '',
    createdAt: '',
    updatedAt: '',
    type: '',
    uploadUsage: 0,
    lessonRecords: {},
    members: [],
    statistics: {}
  }

  // <--- Actions --->
  setCurrentUser(user) {
    this.currentUser = user
  }

  logout() {
    this.currentUser = {
      id: null,
      username: '',
      subscriptionType: '',
      firstName: '',
      lastName: '',
      createdAt: '',
      updatedAt: '',
      type: '',
      uploadUsage: 0,
      lessonRecords: {},
      members: [],
      statistics: {}
    }

    window.parent.postMessage('arcade-logout', '*')
  }

  // <--- Flow --->

  // async login(credentials) {
  //   const { user } = await login(credentials);
  //   if (user) {
  //     this.currentUser = user;
  //   }
  //   console.log("#DEBUG", "user login", user);
  // }

  async loginJWT(token) {
    if (this.currentUser.id === null) {
      try {
        const user = await userFromToken(token);
        this.setCurrentUser(user)
      } catch(err) {
        console.log(err)
        // window.parent.postMessage('arcade-login')
      }
    }
  };
}

export default UserStore;
