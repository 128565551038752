import { useEffect } from "react";
import useQuery from "../hooks/use-query";
import { useUserStore, useArcadeStore } from "../state";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core";
import Home from "../pages";

const useStyles = makeStyles((theme) => ({}));

const Arcade = () => {
  const query = useQuery();
  const userStore = useUserStore();
  const arcadeStore = useArcadeStore();
  const classes = useStyles();

  
  useEffect(() => {
    const doLogin = async () => {
      try {
        const token = query.get("token");
        await userStore.loginJWT(token);;

        arcadeStore.initialFetchGames();
        arcadeStore.fetchFeaturedGames();

        window.parent.postMessage("arcade-ready", "*");
      } catch (err) {
        console.log(err);
      }
    };

    doLogin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Home />
    </div>
  );
};

export default observer(Arcade);
