import React from 'react'
import { useUserStore } from '../../state'

import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'

import imgController from '../../img/controller-emoji.png'
import imgHeart from '../../img/red-heart.png'

const useStyles = makeStyles({
  text: {
    fontFamily: '"Fredoka One", cursive;',
    WebkitFontSmoothing: 'antialiased',
    textTransform: 'none',
    fontSize: 20,
    // backfaceVisibility: 'hidden',
    color: '#FFF'
  },
  tabs: {
    padding: '10px 0 20px 0',
  },
  tab: {
    minWidth: 0,
    padding: 0,
    margin: 'auto'
  },
  indicator: {
    backgroundColor: '#feea00',
    height: 4
  },
  image: {
    marginRight: 8,
  }
});

const CategoryTabs = ({ handleChange, value }) => {
  const classes = useStyles()
  const userStore = useUserStore()

  return (
    <Tabs
      className={classes.tabs}
      classes={{ indicator: classes.indicator }}
      value={value}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChange}
      aria-label="disabled tabs example"
    >

      <Tab
        disableTouchRipple
        style={{ marginRight: 30 }}
        className={classes.tab}
        label={
          <div style={{ display: 'flex' }}>
            <img
              style={{ width: 28, height: 28 }}
              className={classes.image}
              src={imgController}
              alt='Recent'
            />
            <Typography className={classes.text}>Recent</Typography>
          </div>
        }
      />
      {userStore.currentUser.id &&
        <Tab
          disableRipple
          style={{ marginRight: 15 }}
          className={classes.tab}
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                style={{ width: 24, height: 24 }}
                className={classes.image}
                src={imgHeart}
                alt='Favourite'
              />
              <Typography className={classes.text}>My Favourites</Typography>
            </div>
          }
        />
      }
    </Tabs>
  )
}

export default CategoryTabs
