import { projectService, likeService } from './client'

const PAGE_SIZE = 48

export const fetchProjects = async ({ offset = 0, limit = PAGE_SIZE, censor = true, searchQuery }) => {
  const { data, flags } = await projectService.find({
    query: {
      $limit: limit,
      $skip: offset,
      ...(searchQuery && {
        name: {
          $like: `%${searchQuery}%`
        }
      }),
      $sort: {
        createdAt: -1
      },
      meta: {
        $in: ['{"published":true}']
      },
      include: [
        {
          service: 'games-likes',
          as: 'likes',
        },
        {
          service: 'user',
          as: 'creator',
          required: true
        },
        {
          service: 'game-comments',
          as: 'comments',
          attributes: ['id'],
        }
      ],
      flags: {
        censor,
      }
    },
  })

  const { total } = await projectService.find({
    query: {
      $limit: 0,
      ...(searchQuery && {
        name: {
          $like: `%${searchQuery}%`
        }
      }),
    }
  })
  // const total = 0

  return { data, total, flags }
}

export const fetchProject = async (id) => {
  const res = await projectService.find(
    {
      query: {
        id,
        $limit: 1,
        include: [
          {
            service: 'games-likes',
            as: 'likes',
          },
          {
            service: 'user',
            as: 'creator',
            required: true
          },
          {
            service: 'game-comments',
            as: 'comments',
          },
        ],

      }
    }
  )

  return res.data.length && res.data[0]
}

export const fetchLikedGames = async (id) => {
  const { data } = await likeService.find({
    query: {
      user_id: id,
    }
  })

  const projectIds = data.map(x => x.game_id)

  if (projectIds.length > 0) {
    return await projectService.find({
      query: {
        id: {
          $in: projectIds,
        },
        include: [
          {
            service: 'games-likes',
            as: 'likes',
          },
          {
            service: 'user',
            as: 'creator',
            required: true
          },
          {
            service: 'game-comments',
            as: 'comments',
          },
        ],
      }
    })
  }
}

export const fetchFeaturedGames = async (ids) => {
  return await projectService.find({
    query: {
      featured: 1,
      meta: {
        $in: ['{"published":true}']
      },
      include: [
        {
          service: 'games-likes',
          as: 'likes',
        },
        {
          service: 'user',
          as: 'creator',
          required: true
        },
        {
          service: 'game-comments',
          as: 'comments',
          attributes: ['id'],
        }
      ],
    }
  })
}

export const likeGame = game_id => {
  return likeService.create({
    user_id: '$userId',
    game_id
  })
}

export const unlikeGame = game_id => {
  return likeService.remove(
    null,
    {
      query: {
        user_id: '$userId',
        game_id
      }
    }
  )
}