import { observer } from 'mobx-react-lite'
import ClipLoader from 'react-spinners/ClipLoader'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'

import {
  useCommentStore,
  useUserStore
} from '../../../state'

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    marginTop: 12,
    marginBottom: 12
  },
  textButton: {
    fontFamily: "'Fredoka One', cursive;",
    textTransform: "none",
    fontSize: 16,
    marginRight: 12
  },
}))

const NewComment = ({
  context,
  gameId,
  parentId,
  disabled,
  closeReply
}) => {
  const commentStore = useCommentStore()
  const userStore = useUserStore()
  const classes = useStyles()

  const { username, id } = userStore.currentUser

  const handleSubmit = () => {
    commentStore.createComment({ gameId, parentId, username, id })
    closeReply && closeReply()
  }

  const handleCancel = () => {
    commentStore.updateBody('')
    closeReply && closeReply()
  }

  return (
    <>
      <TextField
        style={context === 'reply' ? { marginLeft: 45, width: 'calc(100% - 45px)' } : undefined}
        variant="filled"
        value={disabled ? '' : commentStore.body}
        onChange={e => commentStore.updateBody(e.target.value)}
        rows={4}
        placeholder={context === 'main' ? 'Share something with the group...' : 'Send a reply...'}
        disabled={disabled}
        multiline
        fullWidth={context === 'main'}
      />

      <Grid container justify='flex-end' alignItems='center' className={classes.buttonContainer}>
        <span style={{ marginRight: 12 }}>
          <ClipLoader
            loading={commentStore.submittingComment}
            color={"#0085ff"}
            size={25}
          />
        </span>

        <Button
          style={context === 'reply' ? { fontSize: 12 } : undefined}
          className={classes.textButton}
          color='primary'
          onClick={handleCancel}
          disabled={disabled}
        >
          CANCEL
      </Button>

        <Button
          style={context === 'reply' ? { fontSize: 12 } : undefined}
          variant='contained' color='primary' className={classes.textButton}
          onClick={handleSubmit}
          disabled={disabled}
        >
          COMMENT
      </Button>
      </Grid>
    </>
  )
}

export default observer(NewComment)