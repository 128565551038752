import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'

import { useArcadeStore } from '../../state'

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    height: 28,
    display: 'flex',
    alignItems: 'center',
    maxWidth: 350,
    backgroundColor: '#628ae5',
    borderRadius: 20,
    alignSelf: 'center',
    flex: 1
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "#FFF",
    fontWeight: 500,
    height: 8,
  },
  icon: {
    color: "#FFF",
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
}))

const GameSearch = () => {
  const classes = useStyles()
  const arcadeStore = useArcadeStore()
  const doSearch = () => {
    arcadeStore.doSearch()
  }
  return (
    <Paper className={classes.root}>
      <SearchIcon className={classes.icon} />

      <InputBase className={classes.input}
        placeholder="Search for a game..."
        value={arcadeStore.search}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            doSearch()
          }
        }}
        onChange={(e) => arcadeStore.setSearch(e.target.value)} />
      {/* <IconButton
        onClick={() => doSearch()}
        color='primary'
        className={classes.iconButton}
        aria-label="Search"
      >
        <SearchIcon />
      </IconButton> */}
    </Paper>
  )
}

export default observer(GameSearch)
