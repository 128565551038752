import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import Container from '@material-ui/core/Container'
import makeStyles from '@material-ui/styles/makeStyles'

import PulseLoader from 'react-spinners/PulseLoader'

import AppBar from '../components/common/AppBar'
import GameLayout from '../components/play/GameLayout'
import { useParams } from 'react-router-dom'
import {
  useArcadeStore,
  useCommentStore
} from '../state'

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "calc(100vh - 48px)",
    backgroundColor: "#FAFAFA",
    boxSizing: 'border-box'
  },
  container: {
    marginTop: 48,
    maxWidth: 860,
    height: "100%",
  },
  loader: {
    width: "100%",
    textAlign: 'center',
    paddingTop: 96,
  }
}));

const Play = () => {
  const { id } = useParams()
  const classes = useStyles()
  const arcadeStore = useArcadeStore()
  const commentStore = useCommentStore()

  useEffect(() => {
    const doLogin = async () => {
      await commentStore.initialFetch(id)
    }

    window.scrollTo(0, 0)
    arcadeStore.resetFocus()
    arcadeStore.setFocusGame(id)

    doLogin()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <AppBar />
        {(arcadeStore.gameInFocus.id) ? (
          <GameLayout url={arcadeStore.gameInFocus.url} gameId={id} />
        ) : (
          <div className={classes.loader}>
            <PulseLoader
              loading={true}
              color={"#3165ec"}
            />
          </div>
          )
        }
      </Container>
    </div>
  )
}

export default observer(Play)
