import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import OutlinedButton from './OutlinedButton'

const ConfirmDialog = ({
  title,
  open,
  handleClose,
  handleAgree,
  handleDisagree,
  isSubmitting,
  prompt,
  closeButton,
  children
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        {closeButton && <IconButton className="float-right" onClick={handleClose}>
          <CloseIcon />
        </IconButton>}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <OutlinedButton
          onClick={handleDisagree}
          color='#fc4b6c'
          size='small'
          disabled={isSubmitting !== undefined ? isSubmitting : false}
        >
          {prompt ? prompt.disagree : 'No'}
        </OutlinedButton>

        <OutlinedButton
          onClick={handleAgree}
          color="#2196f3"
          size='small'
          disabled={isSubmitting !== undefined ? isSubmitting : false}
        >
          {prompt ? prompt.agree : 'Yes'}
        </OutlinedButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
