import React from 'react'
import { observer } from 'mobx-react-lite'
import { motion } from 'framer-motion'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";

import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorderRounded";
import FavoriteIcon from "@material-ui/icons/FavoriteRounded";

import useGameLiked from '../../hooks/useGameLiked'

import GameFrame from './GameFrame'

import { useArcadeStore } from '../../state'
import { getURLs } from '../../utils/get-urls'

const { bucketName } = getURLs();

const useStyles = makeStyles({
  root: {
    marginTop: 15,
    // boxShadow: "0 0 1px rgba(34, 25, 25, 0.4)",
    height: 410,
    overflow: "hidden",
    width: "100%",
    borderRadius: 3,
    border: "1px solid transparent",
    borderTop: "none",
    borderBottom: "1px solid #DDD",
    boxShadow: "inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px #FFF, 0 1px 0 #FFF"
  },
  flex: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    fontFamily: '"Fredoka One", cursive',
    WebkitFontSmoothing: "antialiased",
    fontSize: 18,
    color: "#535880"
  },
  footerText: {
    fontFamily: '"Fredoka One", cursive',
    WebkitFontSmoothing: "antialiased",
    fontSize: 12,
    opacity: 0.6,
    color: "#535880"
  },
  dateText: {
    fontSize: 12,
    fontWeight: 500,
    opacity: 0.6,
    color: "#535880"
  },
  avatar: {
    width: 25,
    height: 25,
    marginRight: 6,
    fontSize: 15,
    backfaceVisibility: "hidden",
    fontFamily: '"Fredoka One", cursive',
    WebkitFontSmoothing: "antialiased",
    position: "initial"
  },
  authorText: {
    fontSize: 12,
    backfaceVisibility: "hidden",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  icon: {
    color: "#535880",
    fontSize: 22,
    marginRight: 4,
    opacity: 0.6
  },
  button: {
    fontFamily: '"Fredoka One", cursive',
    WebkitFontSmoothing: "antialiased",
    marginLeft: 8,
    padding: "2px 4px"
  },
  divider: {
    width: "100%",
    height: 3,
    margin: "10px 0",
    backgroundColor: "#e4e7f2"
  },
  descriptionText: {
    color: "#7e828b",
    fontSize: 13
  }
});

const EMPTY_DESCRIPTION = 'No Description'

const GameLayout = ({ url }) => {
  const classes = useStyles()
  const {
    gameInFocus: { id, likes, name, description, creator, updatedAt, meta }
  } = useArcadeStore()

  const [likeStatus, toggleGameLiked] = useGameLiked(id || 0, likes || 0) // Set default values until data returned

  const src = url?.includes('https://storage.googleapis.com/') ? url : `${bucketName}${url}/index.html`

  return (
    <>
      <div className={classes.root}>
        <div style={{ height: "100%", margin: 1 }}>
          <GameFrame src={src} />
        </div>
      </div>
      <div className={classes.flex} style={{ marginTop: 10 }}>
        <Typography className={classes.title}>{name}</Typography>
        <div style={{ flexGrow: 1 }} />
        <motion.div
          onClick={e => { e.stopPropagation(); toggleGameLiked() }}
          style={{ cursor: "pointer", display: "flex" }}
          whileTap={{ scale: 1 }}
          whileHover={{
            transition: {
              duration: 0.3
            },
            scale: 1.2
          }}
        >
          {likeStatus.likedByMe ? (
            <Fade in={true}>
              <FavoriteIcon
                className={classes.icon}
                style={{ color: "#f92c2c" }}
              />
            </Fade>
          ) : (
            <FavoriteBorderIcon className={classes.icon} />
          )}
          {/* </Fade> */}
        </motion.div>
        <Typography className={classes.footerText}>
          {likeStatus.total}
        </Typography>
      </div>
      <div className={classes.flex}>
        <Typography className={classes.dateText}>
          {moment(updatedAt).format("MMM Do YYYY")}
        </Typography>
      </div>
      <div className={classes.flex}>
        <Avatar
          className={classes.avatar}
          style={{ backgroundColor: "#2de54a", position: "inherit" }}
        >
          {creator.username.trim().slice(0, 1).toUpperCase()}
        </Avatar>
        <Typography
          className={classes.authorText}
          style={{ fontSize: 12, opacity: 0.6 }}
          variant="body2"
        >
          By
        </Typography>
        <Typography
          className={classes.authorText}
          style={{ marginLeft: 3, fontSize: 12, color: "#44a7f7" }}
          variant="body2"
        >
          {creator.username.trim().replace(/@.*$/, "")}
        </Typography>
        <div style={{ flexGrow: 1 }} />
        {!meta.hideRemix &&
          <Button
          // TODO: staging and development variants
            onClick={() => window.open(`${process.env.REACT_APP_GAMEFROOT_CLIENT}/games/remix/${id}`)}
            size="small"
            variant="outlined"
            className={classes.button}
            style={{
              border: "2px solid rgb(45, 229, 74)",
              color: "rgb(45, 229, 74)"
            }}
          >
            Remix
        </Button>
        }
      </div>
      <div className={classes.divider} />
      <Typography className={classes.descriptionText}>
        {description || EMPTY_DESCRIPTION}
      </Typography>
      <div className={classes.divider} />
    </>
  )
}

export default observer(GameLayout)
