import { makeStyles } from '@material-ui/core/styles'
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fade from '@material-ui/core/Fade'
import Fab from '@material-ui/core/Fab'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUpRounded';

import { animateScroll } from "react-scroll";

const useStyles = makeStyles(theme => ({
  upButton: {
    position: 'fixed',
    boxShadow: '0px 1px 8px 0px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 3px 3px -2px rgba(0,0,0,0.12)',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: '#3165ec',
    '&:hover': {
      backgroundColor: '#3165ec',
      filter: 'brightness(90%)'
    }
  }
}))

const THRESHOLD = 300;

const scrollOptions = {
  duration: 600,
  delay: 100,
  smooth: true
};

const ScrollTopButton = ({ children, target }) => {
  const classes = useStyles()
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: THRESHOLD
  })

  return (
    <Fade in={trigger}>
      <Fab className={classes.upButton} onClick={() => animateScroll.scrollToTop(scrollOptions)}>
        <KeyboardArrowUpIcon style={{ color: '#FFF', fontSize: 35 }} />
      </Fab>
    </Fade>
  )
}

export default ScrollTopButton
