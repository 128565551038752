/* eslint-disable no-unused-vars */
import { commentService } from './client'

/**
 * Fetches comments made on a specific game, currently search field is not used
 * @param {Number} id
 * @param {String} search
 * @param {Number} limit
 * @param {Number} offset
 * @returns {Array} All comments on this game
 */

export const fetchCommentsByGame = async ({ gameId, limit, offset = 0 }) => {
  const { data, flags } = await commentService.find({
    query: Object.assign({
      $limit: limit,
      $skip: offset,
      $sort: {
        createdAt: -1
      },
      game_id: gameId,
      parent_comment_id: null,
      include: {
        service: 'user',
        as: 'author',
        required: true,
        attributes: ['id', 'username']
      },
      flags: {
        objectJoin: true,
        censor: true
      },
    })
  })

  return { data, flags }
}

export const createComment = (game_id, body, parent_comment_id) => {
  return commentService.create({
    user_id: '$userId',
    game_id,
    body,
    parent_comment_id
  })
    .then(res => res.id)
}

export const updateComment = (id, body) => {
  commentService.patch(
    id,
    {
      body
    }
  )
}

export const deleteComment = (id) => {
  commentService.remove(id)
}