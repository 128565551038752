import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import { pickColour } from '../../utils/color-picker'

import { makeStyles } from '@material-ui/styles'
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";

import FavoriteIcon from "@material-ui/icons/FavoriteRounded";
import CommentIcon from "@material-ui/icons/CommentRounded";

import imgDefault from '../../img/default.png'
import imgDefault2 from '../../img/default2.png'
import imgDefault3 from '../../img/default3.png'

const CROSSFADE_TIMEOUT = 300

const useStyles = makeStyles(theme => ({
  root: {
    height: 180,
    cursor: "pointer",
    position: "relative"
  },
  image: {
    backfaceVisibility: "hidden",
    backgroundColor: '#3165ec',
    userDrag: "none",
    width: "100%"
  },
  fullHeightImage: {
    height: "100%",
    filter: "brightness(40%)",
    userDrag: "none"
  },
  fullHeightTitle: {
    fontSize: 14,
    color: "#FFF",
    fontFamily: '"Fredoka One", cursive'
  },
  icon: {
    color: "#FFF",
    marginRight: 4,
    width: 20,
    height: 20
  },
  paper: {
    cursor: "pointer",
    position: "relative",
    overflow: "hidden",
    userSelect: "none",
    transition: "background-color 0.3s"
  },
  title: {
    fontSize: 14,
    fontFamily: '"Fredoka One",cursive',
    color: "#535880",
    WebkitFontSmoothing: "antialiased",
    whiteSpace: "nowrap",
    overflow: "hidden",
    backfaceVisibility: "hidden",
    textOverflow: "ellipsis"
  },
  authorText: {
    fontSize: 12,
    backfaceVisibility: "hidden",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  footerText: {
    fontSize: 12.5,
    backfaceVisibility: "hidden",
    opacity: 0.5,
    // fontWeight: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  owner: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    width: 20,
    height: 20,
    fontFamily: '"Fredoka One", cursive',
    WebkitFontSmoothing: "antialiased",
    marginRight: 6,
    fontSize: 14,
    backfaceVisibility: "hidden",
    position: "initial"
  },
  footer: {
    position: "absolute",
    display: "flex",
    bottom: 10,
    left: 10
  }
}));

const images = [
  imgDefault,
  imgDefault2,
  imgDefault3
]

const GameCard = ({
  name,
  url,
  id,
  description,
  thumbnail,
  updatedAt,
  i,
  creator,
  likes,
  comments,
  size = 'md'
}) => {
  const classes = useStyles()
  const [showMore, setShowMore] = useState(null)

  const trimmedName = name.length > 42
    ? name.slice(0, 42).trim() + '...'
    : name

  /** If username resembles an email, strip it back. */
  const safeUsername = creator.username.replace(/@.*$/, "")
  const trimmedUsername = safeUsername.length > 50
    ? safeUsername.slice(0, 50).trim() + '...'
    : safeUsername

  return (
    <Link to={`/play/${id}`} style={{ textDecoration: 'none' }}>
      <motion.div
        whileTap={{ scale: 1 }}
        whileHover={{
          transition: {
            duration: 0.3
          },
          scale: 1.02
        }}
      >
        <Paper
          onMouseEnter={() => setShowMore(true)}
          onMouseLeave={() => setShowMore(false)}
          elevation={4}
          className={classes.paper}
          style={{
            height: size === "sm" ? 160 : 190,
            backgroundColor: showMore ? "transparent" : "#FFF"
          }}
        >
          <Fade
            in={!showMore}
            timeout={showMore === null ? 0 : CROSSFADE_TIMEOUT}
          >
            <div>
              <div
                style={{ height: size === "sm" ? 90 : 110, overflow: "hidden" }}
              >
                <img
                  className={classes.image}
                  style={{ width: "100%" }}
                  src={thumbnail || images[i % 3]}
                  alt='Project thumbnail'
                />
              </div>
              {/* </motion.div> */}
              <div style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 6 }}>
                <Typography className={classes.title}>{name}</Typography>
                <div
                  className={classes.owner}
                  style={{ margin: size === "sm" ? 0 : "4px 0" }}
                >
                  <Avatar
                    className={classes.avatar}
                    style={{
                      backgroundColor: pickColour(i, true),
                      position: "inherit"
                    }}
                  >
                    {creator.username
                      .trim()
                      .charAt(0)
                      .toUpperCase()}
                  </Avatar>
                  <Typography
                    className={classes.authorText}
                    style={{ fontSize: 12 }}
                    variant="body2"
                  >
                    {safeUsername}
                  </Typography>
                </div>
                <Grid container>
                  <Grid style={{ overflow: "hidden" }} item xs={6}>
                    {/* {moment().startOf('day').fromNow()} */}
                  </Grid>
                  <Grid
                    style={{ overflow: "hidden", textAlign: "end" }}
                    item
                    xs={6}
                  >
                    <Typography className={classes.footerText}>
                      {moment(updatedAt).fromNow()}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Fade>

          <Fade in={showMore} timeout={CROSSFADE_TIMEOUT}>
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                borderRadius: 4,
                overflow: "hidden",
                top: 0,
                left: 0
              }}
            >
              <img
                className={classes.fullHeightImage}
                src={thumbnail || images[i % 3]}
                alt='Project thumbnail'
              ></img>
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0
                }}
              >
                <div style={{ padding: 10 }}>
                  <Typography className={classes.fullHeightTitle}>
                    {trimmedName}
                  </Typography>
                  <div className={classes.owner}>
                    <Avatar
                      className={classes.avatar}
                      style={{
                        backgroundColor: pickColour(i, true),
                        position: "inherit",
                        margin: "4px 0"
                      }}
                    >
                      {creator.username
                        .trim()
                        .charAt(0)
                        .toUpperCase()}
                    </Avatar>
                    <Typography
                      style={{ fontSize: 12, color: "#FFF", marginLeft: 6 }}
                      variant="body2"
                    >
                      {trimmedUsername}
                    </Typography>
                  </div>
                  <Typography
                    style={{ fontSize: 12, fontWeight: 500, color: "#FFF" }}
                  >
                    {moment(updatedAt).format("MMM Do YYYY")}
                  </Typography>
                </div>
                <div className={classes.footer}>
                  <FavoriteIcon
                    className={classes.icon}
                    style={{ color: likes.likedByMe ? "#df2540" : "#FFF" }}
                  />
                  <Typography
                    style={{ color: "#FFF", fontSize: 14, marginRight: 10 }}
                  >
                    {likes.total}
                  </Typography>
                  <CommentIcon className={classes.icon} />
                  <Typography
                    style={{ color: "#FFF", fontSize: 14, marginRight: 10 }}
                  >
                    {comments?.length}
                  </Typography>
                </div>
              </div>
            </div>
          </Fade>
        </Paper>
      </motion.div>
    </Link>
  )
}

export default observer(GameCard)
