import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useArcadeStore } from '../../state'

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography"

import GameCard from '../common/GameCard'

const useStyles = makeStyles({
  title: {
    fontFamily: '"Fredoka One", cursive',
    fontSize: 14,
    opacity: 0.6,
    color: "#535880",
    marginBottom: 10
  },
});

const MoreGames = () => {
  const classes = useStyles()
  const arcadeStore = useArcadeStore()
  const [moreGames, setMoreGames] = useState([])

  useEffect(() => {
    if (!arcadeStore.latestGames.length) {
      arcadeStore.initialFetchGames(8)
    } else {
      const max = arcadeStore.latestGames.length - 8
      const startIndex = Math.floor(Math.random() * (max))

      const more = arcadeStore.latestGames.filter((game, i) => 
        i >= startIndex && i <= startIndex + 8);
      setMoreGames(more)
    }
  }, [])

  useEffect(() => {
    if (arcadeStore.currentGameInSelection) arcadeStore.extendSelection(1)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arcadeStore.currentGameInSelection])

  return (
    <div>
      <Typography className={classes.title}>More Games</Typography>
      {moreGames.map((game, i) => (
        <div style={{ marginBottom: 10 }} key={i}>
          <GameCard {...game} size="sm" i={i} />
        </div>
      ))
      }
    </div>
  )
}

export default observer(MoreGames)
