import { useEffect, useRef } from 'react'
import { useArcadeStore } from '../../state'
import { observer } from 'mobx-react-lite'

import Grid from '@material-ui/core/Grid'

import ScrollTopButton from '../common/ScrollTopButton'
import LoadMore from '../common/LoadMore'
import GameCard from '../common/GameCard'
import Error from '../common/Error'

const LatestGames = props => {
  const arcadeStore = useArcadeStore()
  const cardRefs = useRef({})

  useEffect(() => {
    if (arcadeStore.gameInFocus?.id) {
      cardRefs.current[arcadeStore.gameInFocus.id]?.scrollIntoView({ behaviour: 'smooth' })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    loadingGames,
    outOfGames,
    latestGames,
    extendSelection,
    loadingGameError,
  } = arcadeStore

  return (
    <>
      <Grid container spacing={2}>
        {latestGames.map((game, i) => (
          <Grid xs={4} sm={3} item key={i} ref={el => cardRefs.current[game.id] = el}>
            <GameCard key={game.id} i={i} {...game} />
          </Grid>
        ))}
      </Grid>

      {loadingGameError ? (
        <Error message={loadingGameError} color='white' />
      ) : (
          <LoadMore
            loading={loadingGames}
            hasMore={!outOfGames}
            displayEOR={latestGames.length > 48}
            endMarker='No More Games'
            getNext={() => extendSelection(latestGames.length)}
            auto={true}
          />
        )
      }

      <ScrollTopButton />
    </>
  )
}

export default observer(LatestGames)