import React, { createContext, useContext, useState } from "react";

import UserStore from "./UserStore";
import ArcadeStore from './ArcadeStore'
import CommentStore from './CommentStore'

const UserContext = createContext(null);
const ArcadeContext = createContext(null)
const CommentContext = createContext(null)

export const UserProvider = ({ children }) => {
  const [store] = useState(new UserStore())
  return (
    <UserContext.Provider value={store}>
      {children}
    </UserContext.Provider>
  )
};

export const ArcadeProvider = ({ children }) => {
  const [store] = useState(new ArcadeStore())
  return (
    <ArcadeContext.Provider value={store}>
      {children}
    </ArcadeContext.Provider>
  )
}

export const CommentProvider = ({ children }) => {
  const [store] = useState(new CommentStore())
  return (
    <CommentContext.Provider value={store}>
      {children}
    </CommentContext.Provider>
  )
}

const providerError = (storeName) => {
  throw new Error(`You have forgotten to use ${storeName} provider`);
};

export const useUserStore = () => {
  const store = useContext(UserContext);
  if (!store) throw providerError("User Store");
  return store;
};

export const useArcadeStore = () => {
  const store = useContext(ArcadeContext)
  if (!store) throw providerError('Arcade Store')
  return store
}

export const useCommentStore = () => {
  const store = useContext(CommentContext)
  if (!store) throw providerError('Comment Store')
  return store
}
